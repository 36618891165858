import React, { useContext } from "react";
import { Link } from "gatsby";
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import { useI18next } from 'gatsby-plugin-react-i18next'
import Accordion from 'react-bootstrap/Accordion';
import { Col, Row } from "react-bootstrap";
import BtnPlusFill from "../BtnPlusFill";

const ServicesComponent = ({ data }) => {
  const { language } = useI18next()

  const {
    translateUrl // (url(, locale)) => translatedUrl
  } = useContext(TranslateUrlsContext)


  return (
    <div className="work-main-container container-margin">
      <Accordion defaultActiveKey={data.services[0].slug}>
        {data.services?.map((item, index) => (
          <Accordion.Item eventKey={item.slug} key={item.slug + index}>

            <Accordion.Header>
              <span className="white fs-2 ILightBeta">{item.title}</span>
            </Accordion.Header>
            <Accordion.Body>
              <Row className="position-relative h-100">
                <Col sm="5" md="5" lg="5">
                  {item.areas?.map((area, index2) => (
                    <p className="ILightBeta white fs-6 uppercase lh-lg" key={area + index2}>{area}</p>
                  ))}
                </Col>
                <Col sm="6" md="6" lg="6" >
                  <p className="ILightBeta white fs-5">{item.text}</p>
                  <div className="container-button-main">
                    <Link to={translateUrl(item.btn.btnlink, language)} className="fs-6 ILightBeta b-0 l-0 mt-auto mb-0  d-grid b-0 ms-auto me-0 align-items-end">
                      <div className="d-flex align-middle bg-black pl-1 pr-1 round-corners-10 mb-0 container-button-main-inner" >
                        <p className="uppercase white IBlack fs-6 ILightBeta mb-0 me-2 ms-3" >
                          {item.btn.btntext}
                        </p>
                        <BtnPlusFill classes="max-width-2 me-3" />
                      </div>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div >)
}
export default ServicesComponent;